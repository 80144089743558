
  import Vue from "vue";
  import pageMixin from "@/mixins/page";
  import {blogApi, PostType} from "@/services/blog";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import {MetaInfo} from "vue-meta";
  import PwaCard from "@/components/PwaCard.vue";
  import { ROUTES } from '@/router/routes';
  import {isPrerender} from "@/utils/common";

  const pageLimit = 9;

  export default Vue.extend({
    name: "BlogHome",
    mixins: [pageMixin],
    components: {
      PwaCard,
      PwaFooter: () => import('@/components/layout/PwaFooter.vue'),
    },
    props: {
      tag: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        blogPostList: this.getPageInitialData('blogPostList', []) as PostType[],
        page: 1,
        totalPages: this.getPageInitialData('totalPages', 0),
        routes: ROUTES,
      };
    },
    mounted(): void {
      this.getBlogListData();
      if (!isPrerender) {
        this.removePageInitialData();
      }
    },
    computed: {
      breadcrumb(): BreadcrumbItemType[] {
        return this.tag
          ? [
            BreadcrumbItems.blog,
            {text: this.tag},
          ]
          : [{labelId: BreadcrumbItems.blog.labelId}];
      },
      metaData(): MetaInfo {
        return {
          title: this.$t('631'),
          meta: [
            {name: 'description', content: this.$t('632')},
            {name: 'robots', content: 'index'},
          ],
          link: this.getMetaDataPathLinks(this.tag ? ROUTES.blogTag.path : ROUTES.blogHome.path),
        };
      },
      hasMorePages(): boolean {
        return this.totalPages > this.page;
      },
    },
    methods: {
      async getBlogListData() {
        try {
          this.showLoader(true);
          const { results, pages } = this.tag
            ? await blogApi.getBlogPostListByTags([this.tag], this.page, pageLimit)
            : await blogApi.getBlogPostList(this.page, pageLimit);
          if (this.page > 1) {
            this.blogPostList = [...this.blogPostList, ...results];
          } else {
            this.blogPostList = results;
          }
          this.totalPages = pages;
          if (isPrerender) {
            this.savePageInitialData({blogPostList: results, totalPages: pages})
          }
        } catch (e) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          this.showLoader(false);
        }
      },
      async showMore() {
        this.page = this.page + 1;
        this.getBlogListData();
      },
      resetBlogList() {
        this.page = 1;
        this.totalPages = 0;
        this.getBlogListData();
      },
    },
    watch: {
      currentLang() {
        this.resetBlogList();
      },
      tag() {
        this.resetBlogList();
      },
    }
  });
